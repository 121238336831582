var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.rpManager
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "text-center",
                      attrs: { flat: "", color: "transparent" },
                    },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-icon", { attrs: { large: "", left: "" } }, [
                            _vm._v("mdi-check-all"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-h6 secondary--text" },
                            [_vm._v("NCU utilization status")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _vm.fetching.utilization_status
                            ? _c("dashboard-loader")
                            : [
                                _vm.$isError(_vm.utilStatus)
                                  ? _c("dashboard-error")
                                  : [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: {
                                            size: "88",
                                            color:
                                              _vm.utilStatusInfo[_vm.utilStatus]
                                                .color,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                dark: "",
                                                "x-large": "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.utilStatusInfo[
                                                    _vm.utilStatus
                                                  ].icon
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.utilStatusInfo[
                                                      _vm.utilStatus
                                                    ].label
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "v-list-item-subtitle",
                                                { staticClass: "subtitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.utilStatusInfo[
                                                        _vm.utilStatus
                                                      ].description
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { attrs: { large: "", left: "" } }, [
                        _vm._v("date_range"),
                      ]),
                      _c("div", { staticClass: "text-h6 secondary--text" }, [
                        _vm._v("Term"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "pb-8 ma-auto", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "text-center" },
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [_vm._v(_vm._s(_vm.status.resource_pool_start))]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Start"),
                            ]),
                          ]),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [_vm._v(_vm._s(_vm.status.resource_pool_end))]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("End"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { attrs: { large: "", left: "" } }, [
                        _vm._v("mdi-memory"),
                      ]),
                      _c("div", { staticClass: "text-h6 secondary--text" }, [
                        _vm._v("NCU Capacity (NCU)"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pa-8 pt-4 ma-auto text-center text-h4 secondary--text",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.status.ncu_hours.toLocaleString()) +
                          " NCU hours per term year"
                      ),
                    ]
                  ),
                  _c(
                    "v-container",
                    { staticClass: "pb-8 ma-auto", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "text-center" },
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.status.burst_ncu_limit.toLocaleString()
                                  )
                                ),
                              ]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Burst"),
                            ]),
                          ]),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.status.base_ncu_limit.toLocaleString()
                                  )
                                ),
                              ]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Power save"),
                            ]),
                          ]),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.status.rtc_ncu_limit.toLocaleString()
                                  )
                                ),
                              ]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Round-the-clock"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { attrs: { large: "", left: "" } }, [
                        _vm._v("payment"),
                      ]),
                      _c("div", { staticClass: "text-h6 secondary--text" }, [
                        _vm._v("Current balance (AC)"),
                      ]),
                      _c("v-spacer"),
                      _vm.rpManager
                        ? _c(
                            "v-dialog",
                            {
                              attrs: { width: "500", persistent: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on: dialog }) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({
                                                    on: tooltip,
                                                    attrs,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                large: "",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          {
                                                            ...tooltip,
                                                            ...dialog,
                                                          }
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "cyan",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-cash-fast"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Transfer credits"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2129651768
                              ),
                              model: {
                                value: _vm.transferDialog,
                                callback: function ($$v) {
                                  _vm.transferDialog = $$v
                                },
                                expression: "transferDialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v("Transfer credits"),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "form",
                                          staticClass: "mt-4",
                                          model: {
                                            value: _vm.transferForm,
                                            callback: function ($$v) {
                                              _vm.transferForm = $$v
                                            },
                                            expression: "transferForm",
                                          },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.managedResourcePools,
                                              label: "Recipient",
                                              "item-text": "long_id",
                                              "item-value": "rpid",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.transferRecipient,
                                              callback: function ($$v) {
                                                _vm.transferRecipient = $$v
                                              },
                                              expression: "transferRecipient",
                                            },
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.currencyMask,
                                                expression: "currencyMask",
                                              },
                                            ],
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              prefix: "AC",
                                              "persistent-hint": "",
                                              hint: _vm.transferCreditHint,
                                              rules: [_vm.rules.maxValue],
                                            },
                                            model: {
                                              value:
                                                _vm.creditsToTransferRounded,
                                              callback: function ($$v) {
                                                _vm.creditsToTransferRounded =
                                                  $$v
                                              },
                                              expression:
                                                "creditsToTransferRounded",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            text: "",
                                            disabled: _vm.transferingCredits,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.transferDialog = false
                                            },
                                          },
                                        },
                                        [_vm._v("cancel")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            disabled:
                                              !_vm.transferRecipient ||
                                              _vm.creditsToTransferRounded ===
                                                "0.00",
                                            loading: _vm.transferingCredits,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.transferCredits()
                                            },
                                          },
                                        },
                                        [_vm._v(" transfer ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pa-8 pt-4 ma-auto text-center text-h4 secondary--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentBalanceRounded.toLocaleString()) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-container",
                    { staticClass: "pb-8 ma-auto", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "text-center" },
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatAC(_vm.status.initial_credits)
                                  )
                                ),
                              ]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Initial"),
                            ]),
                          ]),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatAC(_vm.status.top_up_credits)
                                  )
                                ),
                              ]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Top-up"),
                            ]),
                          ]),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "text-h5 secondary--text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatAC(_vm.status.total_credits_used)
                                  )
                                ),
                              ]
                            ),
                            _c("label", { staticClass: "text--secondary" }, [
                              _vm._v("Used"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "transparent" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { attrs: { large: "", left: "" } }, [
                        _vm._v("mdi-order-bool-ascending-variant"),
                      ]),
                      _c("div", { staticClass: "text-h6 secondary--text" }, [
                        _vm._v("Additional services"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        _vm._l(
                          _vm.status.activated_services,
                          function (value, service) {
                            return _c(
                              "v-col",
                              { key: service, attrs: { cols: "4" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    attrs: {
                                      flat: "",
                                      color: "grey lighten-3",
                                      disabled: !_vm.rpManager || value,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleService(service)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      {
                                        attrs: {
                                          "two-line": "",
                                          color: "transparent",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                value
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "primary lighten-1",
                                                        attrs: { dark: "" },
                                                      },
                                                      [_vm._v("check_circle")]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "grey lighten-1",
                                                        attrs: { dark: "" },
                                                      },
                                                      [_vm._v("circle")]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.services[service]
                                                        .label
                                                    )
                                                  ),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.services[service]
                                                        .description
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("additional-storage", {
                attrs: {
                  rpid: _vm.rpid,
                  expired: _vm.utilStatus === "expired",
                  selectedRP: _vm.selectedRP,
                },
                on: { refresh: _vm.getStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }